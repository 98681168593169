import PostMenusComponent from "../components/PostMenus/PostMenus";

export default function PostMenus() {
    return (
        <div>
            <div>
                <PostMenusComponent/>
            </div>
        </div>
    )
}