import AdminComponentComplete from "../components/AdminComplete/AdminComplete";

export default function Admin() {
    return (
        <div>
            <div>
              <AdminComponentComplete/>
            </div>
        </div>
    )
}