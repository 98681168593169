import React, { useEffect} from "react";
import { useLocation } from "react-router-dom";
import styles from "./PoliticPrivate.module.css";

export default function PoliticPrivate() {
  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className={styles.policy_container}>
      <div className="mx-auto max-w-2xl">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
          Políticas de Privacidad de ENUNA
        </h2>
        <p className="mt-4 text-lg leading-8 text-gray-600">
          La presente Política de Privacidad y de Protección de Datos Personales
          tiene por finalidad informar la forma como recopilamos, utilizamos,
          difundimos y/o divulgamos la información obtenida a través de nuestra
          Plataforma y demás Servicios.
          <br />
          Al utilizar los Servicios, usted acepta expresamente sujetarse a las
          disposiciones de nuestra Política de Privacidad y de Protección de
          Datos Personales, que rige el uso de cualquier información
          proporcionada cuando llama, escribe, o se comunica a través de los
          Servicios.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          A. RECOPILACIÓN DE INFORMACIÓN
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Por "Información Personal" <strong>se entiende</strong> aquel permite
          identificación, tales como nombres, apellidos,{" "}
          <strong>documentos de identidad, domicilio</strong>, número de
          teléfono, dirección de correo electrónico, información de su
          localización, número de tarjeta de crédito, detalle de consumos y
          pedidos, <strong>imagen, voz,</strong> entre otros. En el caso de
          números de tarjeta de crédito y código CVC, dicha información es
          encriptada, y es almacenada por la plataforma de pagos.
          <br />
          Con el ingreso a nuestra plataforma y al hacer uso de ella, se presta
          consentimiento, reconoce y se autoriza expresamente, mediante la
          aceptación a la presente Política de Privacidad y de Protección de
          Datos Personales, a hacer uso y realizar tratamiento de toda la
          Información Personal que se proporcione al momento de utilizar los
          Servicios, incluyendo y sin limitarse, a la Información Personal
          obtenida al realizar cualquiera de las siguientes acciones: a)
          Registrar como usuario en nuestra Plataforma, b) Realizar reservas a
          través de la plataforma de ENUNA (página web o aplicaciones móviles),
          el Widget de alguno de nuestros restaurantes afiliados (el Widget es
          el botón/motor de reservas que ENUNA instalada en la Página Web y/o
          redes sociales del restaurante, y/o Página Web o Apps de empresas
          aliadas), y/o a través de la Red de ENUNA (integraciones que ENUNA
          hace con terceros para listar restaurantes y/o recibir reservas en
          línea a través de sus páginas Web y aplicaciones móviles). c) Ingresar
          o actualizar perfiles en nuestra Plataforma por propia cuenta o
          mediante la utilización de un tercer proveedor de acceso virtual
          suministrado (por ejemplo, Facebook y Google, entre otros), en tal
          caso, se autoriza a cada proveedor de inicio de sesión a enviar la
          información de registro de su perfil dentro de su servicio, salvo su
          contraseña. d) Participar en concursos, promociones, invitaciones,
          activaciones, sorteos y ofertas especiales. En caso esta información
          vaya a ser compartida con un tercero, se notificará en el momento de
          la recopilación, pudiendo el usuario negarse a participar en los
          sorteos, promociones, invitaciones, activaciones, concursos u ofertas
          especiales. e) Realizar encuestas voluntarias, paneles y estudios de
          mercado, respecto de los cuales se haya brindado consentimiento. La
          información que se proporcione en respuesta a las preguntas de la
          encuesta, panel o estudio de mercado opcional puede ser compartida,
          pero solo de forma agregada, con nuestros anunciantes y socios. f)
          Navegar por nuestra Plataforma (cookies, dirección IP,) a fin de
          hacerle seguimiento al uso del contenido.
          <br />
          Esta Información Personal estará́ almacenada en nuestros bancos de
          datos personales, salvo el número de su tarjeta de crédito y código
          CVC, que es encriptada, y que es almacenada por la pasarela de pagos,
          que cuenta con las medidas de seguridad necesarias y cumplen con los
          más altos estándares de protección y a las que no se tiene para
          efectos de su Información Personal relacionada con dichos servicios.
          Asimismo, la Información Personal solo será́ compartida con los
          Restaurantes, pasarelas de pago y otros terceros, de ser el caso, que
          sean necesarios para llevar a cabo la gestión, desarrollo y ejecución
          de las Reservas en los Restaurantes, y el procesamiento de los pagos
          llevados a cabo a través de la plataforma de pagos, de conformidad con
          lo dispuesto por la legislación aplicable.
          <br />
          "Información General" se refiere a la información que, por sí misma,
          no permite identificar (por ejemplo, información demográfica,
          geográfica o de visitas al sitio web). La recopilación de la
          Información General se realiza a través de cualquiera de los métodos
          mencionados anteriormente, así́ como de forma automática a través del
          uso de tecnologías estándar de la industria.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          B. USO DE SU INFORMACIÓN
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Con el ingreso y uso de nuestra plataforma, se declara expresamente,
          reconoce y acepta que el tratamiento de la Información Personal se
          realiza dentro del marco de la relación contractual que nace con su
          ingreso a nuestras plataformas; por lo que se nos autoriza a que
          podamos transferir la Información Personal a los Restaurantes
          estrictamente para la gestión, desarrollo y ejecución de las Reservas
          y de los pagos de los consumos efectuados en los Restaurantes a través
          de la plataforma de pagos, así como para el envío de anuncios,
          actualización de servicios, ofertas, invitaciones a sorteos, concursos
          y envío de publicidad, supuesto en el cual sus datos serán almacenados
          en sus sistemas informáticos. En dicho caso, garantizamos el
          mantenimiento de la confidencialidad y el tratamiento seguro de la
          información. La Información Personal o Información General será́
          utilizada, sin ser limitada, para los casos de:
          <br />
          a) Administración del sistema: Se podrá utilizar su Información
          General para los fines de la administración de nuestros sistemas, la
          asistencia en el diagnóstico de problemas con los servidores, la
          supervisión del rendimiento y del tráfico de nuestros sistemas, y
          reunir información demográfica. De considerarse necesario, se
          compartirá Información General disociada o anonimizada con terceros,
          es decir, información que no permita al tercero identificarlo o
          contactarlo personalmente.
          <br />
          b) Comunicación y Publicidad: Se usará la Información Personal para
          enviar periódicamente a la dirección de correo electrónico y/o a
          través de mensajes de texto o cualquier otro medio disponible
          anuncios, actualización de servicios, ofertas, invitarlo a sorteos,
          concursos, enviarle publicidad nuestra y de los Restaurantes u otros
          terceros; encuestas sobre las Reservas, los Servicios recibidos y/o
          sobre cualquier otro tema de interés para garantizar la mejora de su
          experiencia. Se podrá́ solicitar que se omita el envío de las encuestas
          o publicidad a través del correo electrónico: contacto@enunaapp.com .
          Sin perjuicio de ello, recibirá́ correos electrónicos relacionados a la
          gestión de las Reservas, tales como confirmaciones, avisos de
          inasistencia, el detalle de su consumo y otros similares. Asimismo,
          tal como indicamos previamente, los Restaurantes podrán usar la
          Información Personal con la finalidad de enviar anuncios,
          actualización de servicios, ofertas, invitarlo a sorteos, concursos y
          enviarle publicidad; de ocurrir, eximiéndonos totalmente de
          responsabilidad por los correos electrónicos que reciba por parte de
          los Restaurantes, teniendo la posibilidad de requerir que no se le
          remitan, coordinando directamente con ellos.
          <br />
          c) Proveedores de Servicios: Si por los servicios de terceros en los
          que esté involucrada su Información Personal o Información General
          (por ejemplo, servicios de gestión y almacenamiento de datos),
          accedemos a datos personales, solo se transferirá la Información
          Personal o Información General que sea necesaria para que los
          proveedores puedan llevar a cabo sus servicios. El uso de esta
          información nos y los obliga a mantener la confidencialidad sobre la
          Información a la que tenga o pudieran tener acceso, incluyendo sus
          empleados o proveedores.
          <br />
          d) Otros tipos de divulgación: Sin perjuicio de cualquier disposición
          en contrario, contenida en esta Política de Privacidad y de Protección
          de Datos Personales, nos reservamos el derecho, y por la presente se
          nos autoriza expresamente, conforme a las normas sobre la materia y
          mandato expreso de autoridad competente, a compartir su Información
          Personal o Información General: (1) en respuesta a citaciones, órdenes
          judiciales o procesos legales o administrativos de cualquier
          naturaleza, o para establecer, proteger o ejercer nuestros derechos
          legales o defendernos contra demandas, procedimientos administrativos
          e investigaciones de cualquier naturaleza; (2) para investigar,
          prevenir o tomar acción respecto de actividades potencialmente
          ilegales, fraude, o situaciones que implican amenazas potenciales a la
          seguridad de cualquier persona o propiedad; (3) para investigar,
          prevenir o tomar acción respecto del abuso significativo de la
          infraestructura de los Servicios o de internet en general (como el
          envío voluminosos de correo basura (spam), ataques de denegación de
          servicio, o los intentos de comprometer la seguridad de la
          Información); (4) con empresas afiliadas o bajo control común con
          nosotros, en cuyo caso, requeriremos que dichas entidades respeten
          nuestra Política de Privacidad y de Protección de Datos Personales; y
          (5) por ser adquiridos o fusionados con otra entidad, y en los casos
          de reorganizaciones societarias, venta de activos o quiebra.
          <br />
          e) Encuestas: enviar a su correo electrónico y/o a través de mensajes
          de texto o cualquier otro medio disponible, encuestas sobre los
          Servicios que brindamos o los servicios ofrecidos por los Restaurantes
          y/o sobre cualquier otro tema de interés para garantizar la mejora de
          nuestros Servicios.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          C. PROTECCIÓN DE INFORMACIÓN
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Para este fin se realizan los siguientes actos:
          <br />
          a) Medidas de Seguridad. Para impedir el acceso no autorizado,
          alteración, divulgación, pérdida o destrucción de su Información
          Personal e Información General bajo los parámetros establecidos en la
          normativa aplicable de protección de datos personales. Estos
          mecanismos, incluyen sin limitar, revisiones internas de: (a) sistema
          de recopilación de datos; (b) almacenamiento y prácticas de
          procesamiento; (c) medidas de seguridad electrónicas de la plataforma;
          y (d) medidas de seguridad físicas para proteger del acceso no
          autorizado a nuestros espacios físicos y virtuales de almacenamiento
          de la Información Personal e Información General. Nuestros empleados,
          proveedores y agentes que tengan acceso a Información Personal están
          obligados a guardar absoluta confidencialidad y a cumplir con las
          obligaciones establecidas en la normativa aplicable de protección de
          datos personales, pudiendo su incumplimiento estar sujeto a medidas
          disciplinarias, incluyendo el despido y pago de indemnización e inicio
          de procesos judiciales civiles y/o penales, de ser el caso, por el uso
          o divulgación no autorizada de la Información Personal e Información
          General.
          <br />
          b) Enlaces de terceros. Podemos contener enlaces a terceros que pueden
          recopilar su Información Personal e Información General. Además
          podemos utilizar a terceros para proporcionar componentes a nuestros
          sistemas. En cualquier caso, los terceros pueden tener políticas de
          privacidad diferentes y las prácticas de recolección serán
          independientes a la nuestra. Nosotros: (a) no tenemos ninguna
          responsabilidad u obligación por estas políticas o acciones de
          terceros; (b) no nos responsabilizamos de las prácticas de privacidad
          ni del contenido de dichos sitios web; y (c) no damos ninguna garantía
          o representación sobre el contenido, productos o servicios ofrecidos
          en dichos sitios web o la seguridad de cualquier Información Personal
          o General que usted pueda proporcionarles.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          D. PROTECCIÓN DE INFORMACIÓN SOBRE MENORES DE EDAD
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          La protección de la información de los menores de edad, cuyo ejercicio
          esta vinculado a sus representantes y tutores, es una labor conjunta
          con los padres y tutores quienes desempeñan un papel activo de
          supervigilancia en las actividades e intereses en línea de sus hijos.
          Los Servicios no están diseñados para niños, ni podrán ser utilizados
          por menores de 16 años. Nosotros no recogemos a sabiendas Información
          Personal de menores de edad, y no comercializamos los Servicios para
          menores de 16 años.
        </p>

        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          D. PROTECCIÓN DE INFORMACIÓN SOBRE MENORES DE EDAD
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          La protección de la información de los menores de edad, cuyo ejercicio
          esta vinculado a sus representantes y tutores, es una labor conjunta
          con los padres y tutores quienes desempeñan un papel activo de
          supervigilancia en las actividades e intereses en línea de sus hijos.
          Los Servicios no están diseñados para niños, ni podrán ser utilizados
          por menores de 16 años. Nosotros no recogemos a sabiendas Información
          Personal de menores de edad, y no comercializamos los Servicios para
          menores de 16 años.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          E. CAMBIOS EN LA POLÍTICA DE PRIVACIDAD Y DE PROTECCIÓN DE DATOS
          PERSONALES
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Por cambios legislativos la Política de Privacidad y de Protección de
          Datos Personales en cualquier momento puede ser modificada, incluso
          por convenir a nuestros derechos; decisión que será comunicada, de
          requerir consentimiento respecto de la nueva versión de la Política de
          Privacidad y de Protección de Datos Personales en caso varíe alguna de
          las formas de tratamiento se que autoriza mediante la aceptación de la
          presente Política; por lo que se recomienda la revisión periódica de
          esta Política, y especialmente antes de proporcionar Información
          Personal adicional. Las actualizaciones tendrán indicadas las fechas
          en que se realizaron
        </p>

        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          F. ACCESO, RECTIFICACION O CANCELACIÓN DE INFORMACIÓN PERSONAL
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          De desearse que la Información Personal sea actualizada y completa, el
          usuario deberá comunicar tal decisión a través de nuestras
          plataformas. Asimismo, se puede en cualquier momento ejercer los
          derechos concedidos por la normativa vigente de protección de datos
          personales, relacionados con el acceso, inclusión, rectificación,
          supresión y oposición sobre la Información Personal que hubiese
          brindado al momento de acceder a nuestros Servicios, para ello, deberá́
          enviarnos una comunicación al correo electrónico:
          contacto@enunaapp.com En caso de dudas o consultas acerca de esta
          Política de Privacidad y de Protección de Datos Personales, puede
          comunicarse con nosotros de la siguiente manera:
          <br />
          Enviar correo electrónico a contacto@enunaapp.com
        </p>
      </div>
    </div>
  );
}
