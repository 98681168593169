import React, { useEffect} from "react";
import { useLocation } from "react-router-dom";
import styles from "./TermsConditions.module.css";

export default function TermsConditions() {
  const { pathname } = useLocation();


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className={styles.terms_container}>
      <div className="mx-auto max-w-2xl">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl text-center">
          TÉRMINOS Y CONDICIONES DE USO DE ENUNA
        </h2>
        <p className="mt-4 text-lg leading-8 text-gray-600">
          Por el uso de nuestra plataforma de reservas en línea en restaurantes
          afiliados a ENUNA (en adelante, la “Plataforma”), que incluye una
          página web (www.enunaapp.com), aplicaciones móviles para iPhone y
          Android, Widget (botón/motor de reservas que ENUNA instala en la
          Página Web y/o redes sociales del restaurante y/o Página Web o Apps de
          empresas aliadas), Red de ENUNA (integraciones que ENUNA hace con
          terceros para listar restaurantes y recibir reservas en línea a través
          de sus Páginas Web y aplicaciones móviles), aplicaciones y servicios
          (incluyendo sin limitarse a los correos electrónicos y cualquier
          material que le sea proporcionado conjuntamente con los servicios) (en
          adelante, los “Servicios”) que son operados por ENUNA, usted acepta la
          vinculación comercial y contractual formando un acuerdo legal, de
          conformidad con los siguientes términos y condiciones que se describen
          en este documento (los “Términos de Uso”). Nuestra Política de
          Privacidad y de Protección de Datos Personales y la Política de
          Reservas forman parte integrante de estos Términos de Uso. Para
          efectos de estos Términos de Uso y demás Políticas toda referencia a
          “nos”, “nosotros”, “nuestra”, se refiere a ENUNA; reconociendo que al
          acceder a través de cualquiera de las formas glosadas precedentemente,
          considera usted y aprueba que ha leído, entendido y aceptado estar
          sujeto a lo siguiente; y, la presente versión de Términos y
          Condiciones podrá ser actualizado en cualquier momento; y con el uso
          de los servicios que ofrecemos se considerará su aceptación
          incondicional de estos términos.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          1. REGISTRO, CONTRASEÑA Y RESPONSABILIDAD{" "}
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Usted podrá navegar por la Plataforma sin necesidad de tener una
          cuenta, sin embargo, teniendo una cuenta creada en ENUNA podrá
          utilizar los servicios, tales como reservas y pago de los productos
          que expenden los restaurantes afiliados a la Plataforma (los
          “Restaurantes”), podrá hacerlo ingresando los datos personales que le
          sean requeridos en nuestra Plataforma o registrándose con una cuenta
          existente de Facebook y/o Google con la finalidad de abrir una cuenta.
          Usted afirma que cuenta con más de 18 años, que es plenamente capaz y
          competente para aceptar los términos, condiciones, afirmaciones,
          representaciones y garantías contenidas en estos Términos y
          Condiciones y cumplirlos escrupulosamente. Su cuenta de usuario no
          debe incluir el nombre de otra persona con la intención de suplantar a
          esa persona, o ser ofensivo, vulgar u obsceno, o contrario a la moral
          y a las buenas costumbres. Su nombre de usuario y contraseña son
          personales y, por tanto es el único responsable de mantener la
          confidencialidad de sus datos para el inicio de sesión en la
          Plataforma y del uso y acceso a los Servicios con su nombre de usuario
          y/o contraseña. Siempre tendrá habilitada la opción de recuperar su
          contraseña haciendo uso de su correo electrónico; sin perjuicio que
          nos notifique inmediatamente al darse cuenta de la pérdida, robo o uso
          no autorizado de cualquier contraseña, ID de usuario o dirección de
          correo electrónico. No puede transferir, ceder permanente o
          temporalmente o vender el acceso a su cuenta. No seremos responsables
          de cualquier daño relacionado con la divulgación de su nombre de
          usuario o contraseña, o el uso que cualquier persona de a su nombre de
          usuario o contraseña. No puede usar la cuenta de otro usuario sin
          contar con el permiso del usuario. Al crear y utilizar su cuenta de
          usuario, usted acepta: (i) proporcionar información verdadera,
          precisa, actual y completa sobre usted en cualquier formulario de
          registro requerido en los Servicios (dicha información es la "Datos de
          registro"); y (ii) mantener y actualizar de inmediato los Datos de
          registro para mantenerlos verdaderos, precisos, actualizados y
          completos. Si proporciona información que es falsa, inexacta, no
          actual o incompleta, o si ENUNA tiene motivos razonables para
          sospechar que dicha información es falsa, inexacta, no actual o
          incompleta, ENUNA tendrá el derecho de suspender o cancelar su Cuenta
          de Usuario y rechazar cualquier y todo uso actual o futuro de su
          cuenta de usuario. Podemos requerir que usted cambie su nombre de
          usuario y/o contraseña si creemos que su cuenta ya no es segura o si
          recibimos una queja respecto de su nombre de usuario que viole los
          derechos de otra persona natural o jurídica, sin perjuicio de las
          acciones que éstas puedan decidir iniciar en defensa de sus derechos.
          Podemos rechazar el registro, cancelar su cuenta o no permitir el
          acceso a los Servicios, a modo de referencia, más no limitativo, en
          caso incumpla las condiciones de los Términos de Uso y de nuestras
          políticas o actúe en forma contraria a lo dispuesto en ellas, incurra
          en posibles infracciones de carácter legal y/o efectúe un uso
          inadecuado de nuestra Plataforma. ENUNA le otorga una licencia
          limitada, revocable y no exclusiva para utilizar los Servicios
          únicamente para nuestro propio uso razonable en relación con el
          funcionamiento de nuestra plataforma; que consiste en acceder y
          utilizar los nombres, direcciones de correo electrónico, números de
          teléfono, fecha de nacimiento, transacciones de lealtad, visitas a
          restaurantes e historial de compras de las personas que utilizan los
          Servicios ("Usuarios" y dicha información, "Información del usuario")
          para los siguientes fines: (i) Para comunicarse con el Cliente con
          respecto a una reserva de restaurante solicitada por el Cliente,
          incluida la transmisión de información sobre el tiempo de espera y
          actualizaciones; (ii) Para, a solicitud del Cliente, inscribir al
          Cliente en cualquier programa de lealtad aplicable operado a través de
          ENUNA, y comunicarse con el Cliente con respecto al programa de
          lealtad; (iii) Comunicarse con el Cliente para objetivos razonables de
          servicio al cliente, como solicitar comentarios sobre la experiencia
          del Cliente. Cuando se inscribe en nuestros Servicios en línea, debe
          designar una dirección de correo electrónico principal que se
          utilizará para recibir comunicaciones electrónicas. En la medida en
          que ENUNA mantenga un centro de mensajes en línea ahora o en el
          futuro, dicho intercambio de comunicaciones se considerará una
          comunicación electrónica y ENUNA podrá utilizarlo para proporcionarle
          avisos de conformidad con estos Términos, según lo requiera la Ley
          aplicable, o en general con respecto a su (s) cuenta (s) con ENUNA.
          NUNCA se le enviará correo electrónico solicitando información
          confidencial, como números de cuenta, nombres de usuario o
          contraseñas, y NUNCA debe responder a ningún correo electrónico
          solicitando dicha información. Si recibe dicho correo electrónico
          supuestamente de ENUNA, no responda al correo electrónico y notifique
          a ENUNA inmediatamente escribiéndonos a contacto@enunaapp.com ENUNA se
          reserva el derecho de cancelar el uso del Servicio en línea por
          cualquier motivo, incluidos, entre otros, el uso indebido de los
          Servicios o la inactividad, y en cualquier momento sin previo aviso.
          Tiene usted derecho a cancelar su uso del Servicio en línea llamando a
          997915937 o escribiendo a contacto@enunaapp.com Cualquier terminación
          de su uso del Servicio en línea, ya sea iniciada por usted o por
          ENUNA, no afectará ninguno de sus derechos y obligaciones o de ENUNA
          en virtud de estos Términos que hayan surgido antes de la fecha de
          vigencia de dicha terminación. Usted acepta expresamente que el uso de
          los servicios es bajo su propio riesgo. Los servicios y su contenido
          se proporcionan "tal cual" y "según están disponibles" para su uso,
          sin garantías de ningún tipo, ya sean expresas o implícitas, a menos
          que dichas garantías sean legalmente incapaces de exclusión. ENUNA
          proporciona los servicios sobre una base comercialmente razonable y no
          hace declaraciones o garantías de que el sitio web, la aplicación, el
          contenido o cualquier servicio ofrecido en relación con los servicios
          estén o permanecerá ininterrumpido o libre de errores, los derechos de
          propiedad intelectual de cualquier tercero, que defectos deberán
          corregirse, que las páginas web del sitio web, la aplicación,
          cualquier comunicación electrónica. Enuna no garantiza que usted podrá
          acceder o utilizar los servicios en los tiempos o lugares que elija, o
          que Enuna tendrá la capacidad adecuada para los servicios en su
          totalidad o en cualquier área geográfica específica.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          2. PROPIEDAD INTELECTUAL{" "}
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Todos los derechos de propiedad intelectual referidos al contenido y
          diseño de nuestros Servicios son de propiedad nuestra o de terceros (y
          en aquellos casos en que sea de propiedad de terceros contamos con las
          licencias necesarias para utilizarlos con los Servicios). Usted no
          puede utilizar o reproducir ni permitir que nadie use o reproduzca
          cualquier marca registrada, nombre o logotipo que figure en los
          Servicios sin nuestra autorización previa por escrito. El software que
          opera la Plataforma y todas las aplicaciones digitales es software de
          terceros y usted no podrá usarlo con excepción de aquellos usos
          permitidos bajo estos Términos de Uso. Usted no podrá copiar, realizar
          ingeniería inversa, modificar o realizar cualquier transacción con el
          software de nuestros Servicios.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          3. USO DEL CONTENIDO{" "}
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          El contenido que posee esta Plataforma se ha preparado con la
          intención de brindar información sobre los Restaurantes y el servicio
          de reservas en los Restaurantes (en adelante, las “Reservas”) y, pagos
          por adelantado por los consumos a realizar en los Restaurantes. Usted
          podrá utilizar la Información contenida en esta Plataforma únicamente
          con fines vinculados a los Servicios. La información contenida en la
          Plataforma es información que fue proporcionada por los Restaurantes.
          En caso de encontrar errores o falencias en la información provista,
          tales como disponibilidad para las Reservas o de productos que
          expenden los mismos, le agradeceríamos que nos contactara. Usted podrá
          emitir opiniones, comentarios, valoraciones, correos electrónicos,
          sugerencias, preguntas, siempre y cuando, el contenido no sea ilegal,
          amenazante, obsceno, racista, difamatorio, calumnioso, pornográfico y
          que no infrinja lo establecido en los Términos de Uso y demás
          políticas que forman parte integrante de los Términos de Uso. Se
          prohíbe expresamente la utilización de esta Plataforma con fines
          comerciales. Usted no podrá ingresar a nuestra Plataforma por medio de
          un robot u otro dispositivo automático, salvo que cuente con nuestra
          autorización previa por escrito. Tampoco podrá utilizar cualquier
          dispositivo o software que interfiera o intente interferir con el
          funcionamiento de nuestra Plataforma. No deberá utilizar esta
          Plataforma, para, a modo de referencia, más no limitativo: enviar
          correos electrónicos masivos (spam) o correos electrónicos con
          contenido amenazante, abusivo, hostil, ultrajante, difamatorio,
          vulgar, obsceno o injurioso; publicar contenido falso, incorrecto,
          engañoso, calumnioso o difamatorio; recopilar información de los
          usuarios; alentar, incitar o dirigir a otros usuarios a infringir los
          presentes de Términos de Uso o alguna disposición legal; para llevar a
          cabo algún acto ilegal o delictivo; sacar una ventaja indebida,
          ilegítima o de mala fe; interferir con la capacidad de otros usuarios
          de acceder o utilizar la Plataforma. Además, acepta que no: copiará,
          reproducirá, modificará, creará derivaciones, distribuirá o mostrará
          públicamente algún contenido de la Plataforma; usará un lenguaje
          ilícito, abusivo, amenazante, obsceno, vulgar, racista, ni cualquier
          lenguaje que se considere inapropiado o en contra de la moral y las
          buenas costumbres; anunciar o proporcionar enlaces a sitios que
          contengan material ilegal u otro contenido que pueda dañar o
          deteriorar la red personal o computadora de otro usuario.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">4. PAGOS </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Si usted desea hacer uso de nuestra plataforma para realizar consumos
          en los Restaurantes, no es necesario registrar los datos de su tarjeta
          de crédito, dado que la plataforma de pago se encarga de dicho
          proceso, y garantiza que su información esta siendo debidamente
          protegida. De no acudir oportunamente a la reserva, incluyendo la
          tolerancia de 15 minutos de espera, el pedido queda listo para recoger
          en tienda y llevar sin cargo adicional. La plataforma Openpay será la
          encargada de validar la tarjeta de crédito ingresada y procesar los
          pagos que se realicen a través de la la plataforma. Una vez efectuado
          el pago, usted será notificado vía notificación de correo electrónico
          con el resumen de su pedido. El restaurante será el responsable de
          emitirle el comprobante de pago por su compra. Finalmente, podrá
          visualizar el historial de sus consumos mediante la sección “Mis
          Reservas”.
          </p >
        <p className="mt-2 text-lg leading-8 text-gray-600">

          En caso de encontrar errores en el detalle de su consumo a través de
          nuestra plataforma, o por tratarse de consumos que usted no reconoce
          haber realizado, deberá comunicarse directamente con nosotros a los
          datos de contacto especificados en nuestra página web o app, o con la
          entidad bancaria emisora de la tarjeta de crédito, según corresponda,
          y seguir los procedimientos que cualquiera de éstos adopte para la
          solución de este tipo de eventos. En caso de encontrar errores en el
          detalle del cobro de su reserva, o por tratarse de reservas que usted
          no reconoce haber realizado, deberá comunicarse directamente con el
          restaurante o con la entidad bancaria emisora de la tarjeta de
          crédito, según corresponda, y seguir los procedimientos que cualquiera
          de éstos adopte para la solución de este tipo de eventos. Nosotros no
          somos responsables por cualquier consumo fraudulento que se lleve a
          cabo con su tarjeta de crédito. En caso que se determine que la
          operación ha sido fraudulenta solo seremos responsables de devolver el
          cobro de nuestra comisión (en caso corresponda) por haber procesado el
          pago a través de plataforma, mediante un extorno a la tarjeta de
          crédito correspondiente, el cual está sujeto a los términos y
          condiciones de la tarjeta de crédito en cuestión.
          </p >
          <p className="mt-2 text-lg leading-8 text-gray-600">

          En la plataforma, la cancelación del pedido no podrá realizarse. Una
          vez aceptada la reserva, el pedido no podrá ser cancelado ni se podrá
          solicitar la devolución del dinero. Si un producto o pedido es
          cancelado debido a cualquier causa, incluso las que respondan a caso
          fortuito o fuerza mayor, no existirá derecho alguno a efectuar contra
          ENUNA. reclamos por devoluciones o reintegros.
          </p >
          <p className="mt-2 text-lg leading-8 text-gray-600">

          La Plataforma transferirá al Restaurante los pagos que usted haya
          hecho en nuestra Plataforma a través de las pasarelas de pago
          habilitadas. El Restaurante será el responsable de extenderle y
          entregarle su comprobante fiscal por los productos que usted vaya a
          pagar. Usted comprende y acepta que el Servicio se provee tal cual es
          y que ENUNA. no asume responsabilidades por el retraso, entrega
          equivocada o error por parte del Restaurante. Su compra no es
          considerada válida hasta que su pago quede registrado y haya sido
          aprobado por la pasarela de pagos.
          </p >
          <p className="mt-2 text-lg leading-8 text-gray-600">

          Si realizara más de un pago para una misma solicitud de compra, queda
          a criterio del Restaurante determinar si considera válido dicho
          registro. Ningún cambio, reintegro o devolución se encuentran
          permitidos excepto en las condiciones que establezca el Restaurante.
          </p >
          <p className="mt-2 text-lg leading-8 text-gray-600">

          Antes de completar su compra, deberá revisar cuidadosamente su pedido
          y condiciones deseadas, ya que una vez realizado no se permiten
          cambios, reintegros y/o devoluciones salvo las admitidas por el
          Restaurante.
          </p >
          <p className="mt-2 text-lg leading-8 text-gray-600">

          Usted deberá exigir el comprobante de pago por el pedido solicitado
          directa y exclusivamente al Restaurante. ENUNA. funciona como agente
          recaudador del dinero por los pedidos de productos del Restaurante,
          pero no se responsabiliza por entregar comprobantes de pago por los
          mismos ya que no está realizando una venta directa al usuario.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          5. POLÍTICA DE PRIVACIDAD Y DE PROTECCIÓN DE DATOS PERSONALES{" "}
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Nosotros nos comprometemos a proteger su privacidad. Por favor revise
          nuestra Política de Privacidad y de Protección de Datos Personales,
          para mayor información.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          6. SITIOS WEB DE TERCEROS{" "}
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          En nuestra Plataforma se proporcionan referencias a otros sitios web,
          pero nosotros no nos responsabilizamos por el contenido de dichos
          sitios. Todas las conexiones que se proveen a otros sitios, tienen
          como único objetivo brindarles mayores referencias sobre los
          Restaurantes. Las políticas y procedimientos que describimos aquí no
          se aplican a los servicios de terceros. Le sugerimos ponerse en
          contacto con estos sitios directamente para obtener información sobre
          sus términos y condiciones, políticas de privacidad, entre otros.
        </p>

        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          7. CESIÓN DE POSICIÓN CONTRACTUAL{" "}
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Usted reconoce y acepta que nosotros podemos ceder nuestra posición
          contractual o cualquiera de nuestros derechos u obligaciones, o vender
          nuestros activos a cualquier tercero sin necesidad de informarle y
          obtener su consentimiento previo y expreso.
        </p>

        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          8. LIMITACIÓN DE RESPONSABILIDAD E INDEMNIDAD{" "}
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Los Servicios que proporcionamos a través de nuestra Plataforma se
          ofrecen sin ninguna clase de garantía. Nosotros no garantizamos que
          los Servicios estén libres de o no sean afectados con virus u otros
          elementos contaminantes o destructivos.
        </p>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Salvo en los casos de culpa inexcusable y dolo, nosotros (ni ningunos
          de nuestros empleados, proveedores y/o licenciatarios) no seremos
          responsables por el lucro cesante o daño moral que pueda surgir
          directa o indirectamente del uso de (o no uso) de los Servicios,
          aunque cuando hayamos sido advertidos de la posibilidad de que pueden
          surgir tales daños.
        </p>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          A petición nuestra, usted acepta defender, indemnizar y mantenernos
          indemnes (incluyendo a nuestros empleados, proveedores y
          licenciatarios) de cualquier reclamación y gastos, incluyendo
          honorarios razonables de abogados, relacionados con cualquier
          violación suya de estos Términos de Uso o por su uso de cualquiera de
          los Servicios en nuestra Plataforma.
        </p>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Asimismo, respecto a la supresión o modificación de la información
          consignada para las Reservas, Pedidos y Compra que se ponen a su
          disposición a través de la Plataforma y de cualquier cancelación o
          modificación efectuada por los Restaurantes sobre sus Reservas así
          como por los pagos que realice a través de nuestra plataforma, usted
          reconoce y acepta mantenernos indemnes (incluyendo a nuestros
          empleados) de cualquier reclamación y gastos, incluyendo honorarios
          razonables de abogados, relacionados con cualquier demanda, denuncia y
          en general cualquier acción de carácter legal, administrativo y/o
          judicial, siempre que los mismos se encuentren relacionados con las
          Reservas, Pedidos y compra de Experiencias que se realicen a través de
          la Plataforma o por la violación de derechos de propiedad intelectual
          relacionado a contenido o material publicitario proporcionado por los
          Restaurantes.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          10. FUERZA MAYOR{" "}
        </h3>

        <p className="mt-2 text-lg leading-8 text-gray-600">
          Nosotros no seremos responsables o nos encontraremos en incumplimiento
          de cualquier retraso o falla en el rendimiento o la interrupción en la
          prestación de los Servicios que pueda resultar directa o
          indirectamente de cualquier causa o circunstancia más allá de nuestro
          control razonable, incluyendo, pero sin limitarse a fallas en los
          equipos o las líneas de comunicación electrónica o mecánica, teléfono
          u otros problemas de interconexión, virus informáticos, el acceso no
          autorizado, robo, errores del operador, clima severo, terremotos o
          desastres naturales, huelgas u otros problemas laborales, guerras, o
          restricciones gubernamentales.
        </p>
        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          11. RECLAMOS Y QUEJAS{" "}
        </h3>

        <p className="mt-2 text-lg leading-8 text-gray-600">
          Conforme a lo establecido en el Código de Protección y Defensa del
          Consumidor, Ley No. 29571, nuestra Plataforma cuenta con un libro de
          reclamaciones virtual en el que usted podrá formular una queja o
          reclamo respecto de los Servicios que ofrecemos en la Plataforma. El
          mismo que puede ser encontrado en nuestra plataforma
        </p>

        <h3 className="mt-6 text-2xl font-semibold text-gray-900">
          12. LEY APLICABLE Y JURISDICCIÓN{" "}
        </h3>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          Cualquier disputa que se produzca con relación a la validez,
          aplicación o interpretación de estos Términos de Uso, incluyendo la
          Política de Privacidad y de Protección de Datos Personales, será
          resuelta en los Tribunales de Justicia de Lima.
        </p>
      </div>
    </div>
  );
}
