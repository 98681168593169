import React, { useState } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google'; // Importa tanto el proveedor como el login
import axios from 'axios';

const Login = () => {
  const [error, setError] = useState(null);

  const handleLoginSuccess = async (response) => {
    try {
      // Enviar el token de Google a tu servidor backend
      const res = await axios.post('https://en-una-production.up.railway.app/api/auth/google', {
        token: response.credential, // Token JWT de Google
      });

      // Al recibir el token del backend, guardarlo en el localStorage
      localStorage.setItem('token', res.data.token);

      // Puedes redirigir al usuario a la página principal después del login
      window.location.href = '/'; // O cualquier página a la que quieras redirigir
    } catch (error) {
      setError('Error al autenticar el usuario');
      console.error('Login failed:', error);
    }
  };

  const handleLoginError = (error) => {
    setError('Error en la autenticación');
    console.error(error);
  };

  return (
    <GoogleOAuthProvider clientId="962025699097-20o18d8bj0tf8gj0qj3q96acrmvtr720.apps.googleusercontent.com"> {/* Proveedor con tu clientId */}
      <div>
        <h2>Iniciar sesión con Google</h2>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginError}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default Login;
