import MyrestaurantComponent from "../components/MyRestaurant/MyRestaurant";

export default function Myrestaurant() {
    return (
        <div>
            <div>
                <MyrestaurantComponent/>
            </div>
        </div>
    )
}