import ComplaintBookComponent from '../components/ComplaintBook/ComplaintBook';
import Footer from '../components/Footer/Footer';


export default function ComplaintBook() {
    return (
        <div>
      <ComplaintBookComponent/>
      <Footer/>
        </div>
    )
}